.categories-section{
    padding: 30px 30px;
    .empty-box{
        height: 580px;
        width: -webkit-fill-available;
    }
    .card{
        border:none;
        border-radius: 10px;
        box-shadow: 0px 0px 7px 2px #d7d7d7;
        min-height: 720px;

        .card-header{
            font-family: "avenirBlack";
            padding: 0.5rem 2.5rem;;
            color: $white;
            background-color: $red;
            border-top-left-radius: 10px ;
            border-top-right-radius: 10px ;
        }
        .card-product-body{
            max-height: 650px;
            overflow-y: auto;
            overflow-x: hidden;
        }
        .card-body{
            .cat-card{
                cursor: pointer;
                box-shadow: 0px 0px 7px 2px #d7d7d7;
                margin: 10px;
                width: 17rem;
                min-height: auto;
                img{
                    padding: 0px;
                    height: 200px;
                    object-fit: contain;
                }
                .card-body{
                    padding: 10px 20px;
                    background: $light-grey;
                    border-bottom-left-radius: 10px ;
                    border-bottom-right-radius: 10px ;
                    .card-text{
                        font-family: "avenirBlack" ;
                        // font-weight: 700;
                        color: $blue !important;
                        text-decoration: none !important;
                        font-size: 14px;
                    }
                }
                &:hover{
                    box-shadow: 0px 0px 7px 2px #ababab;
                }
            }
        }
    }
}