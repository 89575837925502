.product-section{
    padding-top: 20px;
    box-shadow:  0px 0px 7px 2px $dividen-grey;
    width: 100%;
    height: auto;
    .p-title{
        position: relative;
        // top:20px;
        // bottom: 20px;
        h1{
            font-size: 20px;
            font-family: avenirBlack;
            color: $red;
            position: absolute;
            top: -13px;
            background: white;
            z-index: 1;
            left: 20px;
        }
        hr{
            background-color: $grey;
            height: 3px;
        }
    }
    .products{
        .total-amm{
            padding: 10px 20px;
            background-color: $light-grey;
            color: $red;
            font-weight: bold;
        }
        .product-cart{
            min-height: 250px;
            max-height: 300px;
            overflow-y: auto;
            margin-top: 0rem;
            padding: 5px 0px;
            .cart-item{
                margin: 8px 0px;
                color: $dark-grey;
                font-weight: 500;
                padding: 0 20px;
                border-bottom: 1px solid $dividen-grey;
                p{
                    margin-bottom:8px; 
                    font-size: 11px;
                }
                .item-text{
                   span{
                       margin-left: 5px;
                       color: $red;
                       font-size: 10px;
                       font-weight: bolder;
                       letter-spacing: .2px;
                    }
                }
                .actions{
                    i{
                        padding: 6px;
                        color: $white;
                        background: $blue;
                        margin-right: 5px;
                        font-weight: 400;
                        font-size: 10px;
                    }    
                    span{
                        padding: 4px 9px;
                        border:1px solid $light-grey;
                        margin-right: 5px;
                        font-size: 10px;
                    }
                    .cart-remove{
                        color: $red;
                        background: $white;
                    } 
                }
            }
        }
    }
    .contact-form{
        margin-top: 7%;
    }
    .form-wrap .form-footer .form-btn-wrap {
        padding: 0.55rem 0;
    }
}
.payment-method{
    .modal {
        background-color: #000000cf;
        .modal-dialog{
            height: fit-content !important;
            .modal-content {
                border-radius: .8rem;
                width: 450px;
                .modal-header {
                    padding: 0.4rem  2rem;
                    border-bottom-color: $grey ;
                    letter-spacing: -1px;
                    h5{
                        font-family: avenirBlack;
                        color: $red;
                    }
                    .btn-close {
                        padding: 0.5rem 0.5rem;
                        margin: -0.5rem -0.5rem -0.5rem auto;
                        position: absolute;
                        right: -7px;
                        top: -8px;
                        background-color: $red;
                        color: $white !important;
                        opacity: 1 !important;
                        border-radius: 50%;
                        background-image: url("../assets/close.png");
                    }
                }
                .modal-body{
                    padding: 1rem 2rem;
                    .acc-text{
                        color: $dark-grey;
                        font-size: 12px;
                        letter-spacing: -0.5px;
                        border-bottom: 1px solid $grey;
                        width: 100%;
                        padding-bottom: 5px;
                    }
                }
            }

        }
    }
}