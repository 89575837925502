.products-section{
    padding: 30px 30px;
    .empty-box{
        height: 580px;
        width: -webkit-fill-available;
    }
    .card{
        border:none;
        border-radius: 10px;
        box-shadow: 0px 0px 7px 2px #d7d7d7;
        min-height: 720px;

        .card-header{
            font-family: avenirBlack;
            padding: 0.5rem 2.5rem;
            color: $white;
            background-color: $red;
            border-top-left-radius: 10px ;
            border-top-right-radius: 10px ;
            .back-btn{
                cursor: pointer;
                font-family: avenirRoman;
                i{
                    height: inherit;
                    font-weight: 400 !important;
                    padding: 9px 8px;
                    background-color: #f0f0f0 ;
                    color: $red;
                    font-size: 12px;
                    vertical-align: middle;
                    // height: 34px;
                    border-top-left-radius: 5px ;
                    border-bottom-left-radius: 5px ;
                }
                span{
                    margin:0;
                    padding: 5px 10px;
                    color: $blue;
                    border-top-right-radius: 5px ;
                    border-bottom-right-radius: 5px ;
                    background-color: white;
                    font-size: 16px;
                }
                
            }
        }
        .card-product-body{
            max-height: 676px;
            overflow-y: auto;
            overflow-x: hidden;
        }
        .card-body{ 
            .prod-card{
                cursor: pointer;
                box-shadow: 0px 0px 7px 2px #d7d7d7;
                margin: 10px;
                width: 17rem;
                min-height: auto;
                .prod-img{
                    height: 150px;
                    width: 145px;
                   
                    img{
                        height: 100%;
                        width: 100%;
                    }
                }
                .card-body{
                    padding: 10px 20px;
                    background: $light-grey;
                    border-bottom-left-radius: 10px ;
                    border-bottom-right-radius: 10px ;
                    .product-card{
                        p{margin: 0;}
                        .card-text{
                            font-family: avenirBlack ;
                            font-weight: 700;
                            color: $blue !important;
                            font-size: 14px;
                            // height: 42px;
                            height: 30px;
                            line-height: 14px;
                            margin-right: 10px
                        }
                        .card-model{
                            color: $red;
                            font-weight: bolder;
                            letter-spacing: 0.2px;
                            font-size: 12px;
                        }
                    }
                    i{
                        font-weight: 100;
                        border-radius: 100%;
                        width: 30px;
                        height: 30px;
                        text-align: center;
                        padding: 7px;
                        color: $white;
                        background: $blue;
                        margin-right: 0px;
                        font-weight: lighter;
                        &:hover{
                            &:hover{
                                box-shadow: 0px 0px 7px 2px #ababab;
                            }
                        }
                    }    
                }
                // &:hover{
                //     box-shadow: 0px 0px 7px 2px #ababab;
                // }
            }
        }
    }
}