@font-face {
    font-family: avenirRoman;
    src: url(../assets/fonts/AvenirLTStd-Roman.woff2);
  }
  
  @font-face {
    font-family: avenirBook;
    src: url(../assets//fonts/AvenirLTStd-Book.woff2);
  }
  
  @font-face {
    font-family: avenirBlack;
    src: url(../assets/fonts/AvenirLTStd-Black.woff);
  }
  .line-clamp {
    display           : -webkit-box;
    -webkit-box-orient: vertical;
    overflow          : hidden;
    text-overflow     : ellipsis;
  
    &.line-1 {
      -webkit-line-clamp: 1 !important;
    }
  
    &.line-2 {
      -webkit-line-clamp: 2 !important;
    }
  
    &.line-3 {
      -webkit-line-clamp: 3 !important;
    }
  
    &.line-4 {
      -webkit-line-clamp: 4 !important;
    }
  
    &.line-5 {
      -webkit-line-clamp: 5 !important;
    }
  }

  .empty-box{
    background-color: $light-grey;
    min-height: 250px;
    margin: 20px;
    p{
      color: $red;
      text-align: center;
    }
  }

  .floating-btn{
    position:fixed;
    width:50px;
    height:50px;
    bottom:20px;
    right:20px;
    background-color: $red;
    color:#FFF;
    border-radius:50px;
    text-align:center;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
    span{
      position: absolute;
      background-color: $white;
      color: $blue;
      border-radius: 100%;
      height: 20px;
      width: 20px;
      right: 4px;
      top: 5px;
      font-size: 10px;
      font-weight: bolder;
      border: 3px solid $red;

    }
    i{
      font-size: 22px !important;
    }
  }
  
  .my-float{
    margin-top:15px;
  }

  .mob-view-map-modal {
    .address-container {
        i{
            font-size: 22px;
        }
        p {
            margin: 0;
            font-size: 12px;
            font-family: "lato";
            color: $grey;
        }
        // p:nth-child(1) {
        //     font-size: 1rem;
        //     font-weight: 700;
        // }
    }
    .map-modal-btn {
        border: none;
        border-radius: 4px;
        background-color: $red;
        color: black;
        padding: 10px 40px;

        :hover {
            background: $black;
        }
    }
    .modal-map-gps-btn {
        position: absolute;
        z-index: 1;
        top: 25px;
        width: 40px;
        height: 40px;
        right: 20px;
        border: none;
        border-radius: 2px;
        background: transparent;
        align-self: center;
        display: flex;
        justify-content: center;
        align-items: center;
        filter: grayscale(1) !important;
        :hover {
            filter: grayscale(0) !important;
        }
    }
    .modal-footer {
        border-top: 5px solid $red;
    }
}
