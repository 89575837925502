.header{
    height: 90px;
    box-shadow: 0 4px 2px -2px #f1f1f1;
    .navbar-brand{
        padding: 0px 50px;
        // padding: 20px 70px;
        margin: 0;
        // box-shadow: 0 4px 2px -2px $light-grey;
        img{
            height: 65px;
        }
    }
    .custom-nav{
        height: inherit;
        // padding: 43px;
        padding: 23px;
        background-color: $blue;
        z-index: 1;
        .dropdown{
            button{
                height: 45px;
                background-color: $light-grey;
                color: $black;
                border-top-left-radius: 50px;
                border-bottom-left-radius: 50px;
                padding-left: 30px;
                padding-right: 30px;
                &focus{
                    box-shadow: none;
                    outline: none;
                }
            }
        }
        input{
            height: 40px;
            border-top-left-radius: 50px;
            border-bottom-left-radius: 50px;
            border: 0;
            border-top-right-radius: 50px !important;
            border-bottom-right-radius: 50px !important;
            padding-left: 30px;
        }
        .search-btn{
            background: $red;
            border-radius: 100% !important;
            color: $white;
            margin: 3px;
            position: absolute;
            right: 1px;
            font-size: 14px;
            z-index: 10 !important;
        }
        .socail-icons{
            display: flex;
            list-style-type: none;
            padding: 0;
            align-content: center;
            align-items: center;
            margin: 0 !important;
            li{
                border: 1px solid $white;
                padding: 5px 13px;
                margin-left: 8px;
                color: $white;
                width: 40px;
                height: 40px;
                border-radius: 100%;
              a{
                margin-top: 3px;
                display: block;
                color: inherit;
                font-size: 16px;
                font-family: lato;
                text-align: center;
                text-decoration: none;
                // &:hover{
                //   color: $red !important;
                // }
              }
              &:hover{
                background-color: $red !important;
              }
            }
        }
    }
}