
.colored-toast{
    .swal2-icon.swal2-success {
        border-color: $blue !important;
        color: $blue !important;
    }
    .swal2-success-ring {
        border-color:  rgba(0,85,130,.3) !important;
    }
    .swal2-timer-progress-bar {
        width: 100%;
        height: 0.25em;
       background-color:  rgba(0,85,130,.3) !important;
    }
}

// .colored-toast .swal2-html-container {
//     color: $blue;
// }
// .colored-toast .swal2-close {
//     color: $blue;
// }