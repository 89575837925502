@media (min-width: 1440px){
    .footer .subftr-sec p {font-size: 14px;}
    .form-wrap .form-footer .form-btn-wrap {font-size: 14px;}
    .form-wrap .form-footer {    padding: 13px 20px;}
    .header .custom-nav {height:90px ;}
}
@media (max-width: 1440px) and (min-width: 1200px){
    .header {height: 72px;}
    .header .navbar-brand img {height: 50px;}
    .header .navbar-brand {padding: 0px 40px;}
    .header .custom-nav {padding: 14px;}
    .categories-section, .products-section {padding: 30px 30px;}
    .products-section .card .card-header, .categories-section .card .card-header {padding: 0.5rem 2.5rem;}
    .categories-section .card .card-body .cat-card {width: 15rem;;}
    .products-section .card .card-body .prod-card {width: 15rem;}
    .products-section .card .card-body .prod-card .card-body {padding: 6px 16px;}
    .footer {padding: 20px;}
    .product-section .p-title h1 {font-size: 18px; top: -10px;}
    .form-wrap .formslayouts .forms_wrapper .form__field:placeholder-shown ~ label {font-size: 12px;top: 0.8rem;left: 1rem;}
    .form-wrap .formslayouts .forms_wrapper {padding: 0 10px;}
    .product-section .p-title h1 {left: 10px;}
    .empty-box {margin: 10px}
    .product-section .products .product-cart .cart-item .actions span {padding: 3px 4px;}
    .product-section .products .product-cart .cart-item .actions i { padding: 4px;}
    .product-section .products .product-cart .cart-item {padding: 0 10px;}
    .form-wrap .form-footer {padding: 14px;}
    .footer .subftr-one p {font-size: 14px;}
    .form-wrap .form-footer .form-btn-wrap {font-size: 11px;}
    .form-wrap .formslayouts .forms_wrapper .form-check {   padding: 0px; padding-left: 25px; padding-right: 6px;}
    .header .custom-nav {height:73px ;}
    .form-wrap .formslayouts .p-title h1 { left: 10px;}
    .form-wrap .formslayouts .payment-method {margin: 10px 10px;}


}
@media (max-width: 1200px) and (min-width: 1024px){
    .header {height: 80px;}
    .header .navbar-brand {padding: 6px 40px;}
    .header .custom-nav {padding: 18px;}
    .footer {padding: 20px;}
    .categories-section, .products-section  {padding: 20px 20px;}
    .categories-section .card .card-body .cat-card {width: 10rem;}
    .categories-section .card .card-body .cat-card .card-body {padding: 10px 10px;}
    .categories-section .card .card-body .cat-card .card-body .card-text {font-size: 12px;}
    .categories-section .card .card-body .cat-card img {padding: 0px;height: 130px;object-fit: contain;}
    .products-section .card .card-body .prod-card {width: 12rem;}
    .products-section .card .card-header, .categories-section .card .card-header {padding: 0.5rem 2.5rem;}
    .product-section .p-title h1 {font-size: 22px;top: -12px;left: 10px;}
    .product-section .products .product-cart .cart-item {padding: 0 10px;}
    .product-section .products .product-cart {margin-top: 2rem;height:  300px;}
    .product-section .products .product-cart .cart-item .actions i {padding: 6px;margin-right: 3px;font-size: 8px;}
    .product-section .products .product-cart .cart-item .actions span {padding: 4px 8px;margin-right: 3px;font-size: 10px}
    .product-section .products .product-cart .cart-item p {font-size: 12px;}
    .product-section .products .product-cart .cart-item .item-text span {margin-left: 1px;font-size: 7px;letter-spacing: 0.1px;}
    .form-wrap .formslayouts .forms_wrapper {padding: 0 10px;margin-bottom: 15px;}
    .form-wrap .formslayouts .forms_wrapper input {padding: 0.1rem 0.75rem;}
    .form-wrap .formslayouts .forms_wrapper .form__field:placeholder-shown ~ label {font-size: 10px;top: 0.5rem;left: 1rem;}
    .form-wrap .form-footer .form-btn-wrap {font-size: 12px;padding: 0.5rem 0;}
    .footer .subftr-one p {font-size: 14px;}
    .form-wrap .form-footer {padding: 13px;}
    .footer .subftr-one p a {font-size: 10px;}
    .footer .subftr-sec p {font-size: 10px;}
    .header .custom-nav {height:80px ;}
    .form-wrap .formslayouts .p-title h1 { left: 10px;}
    .form-wrap .formslayouts .payment-method {margin: 10px 10px;}



}

@media (max-width: 1024px) and (min-width: 900px){
    .header {height: 72px !important;}
    .header .navbar-brand {padding: 4px 40px}
    .header .navbar-brand img {width: 110px;}
    .header .custom-nav {padding: 14px;}
    .footer {padding: 20px;}
    .categories-section, .products-section {padding: 20px 20px;}
    .product-section .p-title h1 {font-size: 18px;top: -10px;left: 10px;}
    .product-section .products .product-cart .cart-item {padding: 0 10px;}
    .product-section .products .product-cart {margin-top: 2rem;height:  300px;}
    .product-section .products .product-cart .cart-item .actions i {padding: 6px;margin-right: 3px;font-size: 8px;}
    .product-section .products .product-cart .cart-item .actions span {padding: 4px 8px;margin-right: 3px;font-size: 10px}
    .product-section .products .product-cart .cart-item p {font-size: 12px;}
    .product-section .products .product-cart .cart-item .item-text span {margin-left: 1px;font-size: 7px;letter-spacing: 0.1px;}
    .form-wrap .formslayouts .forms_wrapper {padding: 0 10px;margin-bottom: 15px;}
    .form-wrap .formslayouts .forms_wrapper input {padding: 0.1rem 0.75rem;}
    .form-wrap .formslayouts .forms_wrapper .form__field:placeholder-shown ~ label {font-size: 10px;top: 0.5rem;left: 1rem;}
    .form-wrap .form-footer .form-btn-wrap {font-size: 12px;padding: 0.5rem 0;}
    .categories-section .card .card-body .cat-card {width: 12rem;}
    .products-section .card .card-body .prod-card {width: 12rem;}
    .footer .subftr-one p a {font-size: 10px;}
    .footer .subftr-sec p {font-size: 10px;}
    .products-section .card .card-body .prod-card .card-body .product-card .card-text {font-size: 11px;}
    .products-section .card .card-body .prod-card .prod-img {height: 120px;}
    .products-section .card .card-product-body {max-height: 600px;}
    .form-wrap .form-footer {padding: 13px;}
    .subftr-one p a{ color: $white !important; }  
    .subftr-one p i{ color: $white !important;}  
    .header .custom-nav {height:73px ;}
    .form-wrap .formslayouts .p-title h1 { left: 10px;}
    .form-wrap .formslayouts .payment-method {margin: 10px 10px;}

}

@media (max-width: 900px) and (min-width: 768px){
    .header {height: 80px !important;}
    .header .navbar-brand {padding: 8px 25px;}
    .header .custom-nav {padding: 28px;}
    .navbar-toggler {margin-right: 20px;}
    .categories-section, .products-section {padding: 20px 20px;}
    .footer .subftr-one p a {font-size: 12px;}
    .subftr-one p a{ color: $white !important; }  
    .subftr-one p i{ color: $white !important;}  

}

@media (max-width: 768px) and (min-width: 480px){
    .header {height: 80px !important;}
    .header .navbar-brand {padding: 8px 25px;}
    .header .custom-nav {padding: 28px;}
    .product-section .p-title h1 {font-size: 22px;top: -12px;left: 10px;}
    .categories-section .card .card-body .cat-card {width: 11rem;}
    .products-section .card .card-body .prod-card {width: 12rem;}
    .products-section .card .card-body .prod-card .card-body .product-card .card-text {font-size: 12px; height: 25px;line-height: 12px;}
    .products-section .card .card-body .prod-card .card-body .product-card .card-model {font-size: 8px;}
    .categories-section, .products-section {padding: 20px 20px;}
    .footer {padding: 10px;}
    .navbar-toggler {margin-right: 20px;}
    .product-section .products .product-cart {min-height: 150px  ;max-height: 150px;}
    .form-wrap .formslayouts .forms_wrapper {padding: 0 10px;margin-bottom: 15px;}
    .form-wrap .formslayouts .forms_wrapper input {padding: 0.1rem 0.75rem;}
    .form-wrap .formslayouts .forms_wrapper .form__field:placeholder-shown ~ label {font-size: 10px;top: 0.5rem;left: 1rem;}
    .form-wrap .form-footer .form-btn-wrap {font-size: 12px;padding: 0.5rem 0;}
    .empty-box {min-height: 120px;margin: 10px;}
    .subftr-one p a{ color: $white !important; }  
    .subftr-one p i{ color: $white !important;}  
    .footer .subftr-sec p {font-size: 12px;}
    

}

@media (max-width: 480px) and (min-width: 320px){
    .subftr-one p a{ color: $white !important; font-size: 12px;}  
    .subftr-one p i{ color: $white !important;}  
    .product-section .p-title h1 {font-size: 22px;top: -12px;left: 10px;}
    .header {height: 81px !important;}
    .header .navbar-brand img {width: 110px;}
    .header .custom-nav {padding: 10px;}
    .header .navbar-brand {padding: 10px 10px;}
    .footer {padding: 10px 0px;}
    .navbar-toggler {margin-right: 10px;}
    .products-section .card .card-body .prod-card {width: 18rem;}
    .categories-section .card .card-body .cat-card {width: 18rem;}
    .categories-section, .products-section {padding: 10px 10px;}
    .products-section .card .card-header {padding: 0.5rem 0.5rem;}
    .categories-section .card .card-header {padding: .5rem 0.5rem;}
    .product-section .products .product-cart {min-height: 200px;max-height: 200px; margin-top: 1rem;}
    .empty-box {min-height: 150px;margin: 10px;}
    .product-section .contact-form {margin-top: 10%;}
    .product-section .p-title {top: 0px;}
    .modal-header {padding: 1rem 1rem 0;}
    .form-wrap .formslayouts .forms_wrapper {padding: 0 10px;margin-bottom: 15px;}
    .form-wrap .formslayouts .forms_wrapper input {padding: 0.1rem 0.75rem;}
    .form-wrap .formslayouts .forms_wrapper .form__field:placeholder-shown ~ label {font-size: 10px;top: 0.5rem;left: 1rem;}
    .form-wrap .form-footer .form-btn-wrap {font-size: 12px;padding: 0.5rem 0;}
    .footer .subftr-sec p {font-size: 12px;}
    .header .navbar-brand img {height: 50px;}
    .payment-method .modal .modal-dialog {height: fit-content !important;width: 90%;margin-left: 16px;}
}


@media (max-width: 2048px) and (max-height:1152px){
    .products-section .card .card-product-body {
        max-height: 930px;
    }
    .products-section .card { max-height: 935px;}
    .categories-section .card .card-product-body {
        max-height: 930px;
    }
    .categories-section .card { min-height: 935px;}
    .empty-box {
        min-height: 280px;
    }
    .products-section .empty-box {
        height: 806px;
    }
    .categories-section .empty-box {
        height: 806px;
    }
    .product-section .products .product-cart {
        min-height: 333px;
    }
    .form-wrap .form-footer {
       margin-top: 15px;
    }
}


@media (max-width: 1920px) and (max-height:1200px){
    .products-section .card .card-product-body {
        max-height: 930px;
    }
    .products-section .card {min-height: 980px;}
    .categories-section .card .card-product-body {
        max-height: 930px;
    }
    .categories-section .card { min-height: 980px;}
    .empty-box {
        min-height: 320px;
    }
    .products-section .empty-box {
        height: 842px;
    }
    .categories-section .empty-box {
        height: 842px;
    }
    .product-section .products .product-cart {
        min-height: 430px;
    }
    .form-wrap .form-footer {
        margin-top: 20px;
    }
}

@media (max-width: 1920px) and (max-height:937px){
    .products-section .card .card-product-body {
        max-height: 778px;
    }
    .products-section .card {min-height: 824px;}
    .categories-section .card .card-product-body {
        max-height: 778px;
    }
    .categories-section .card { min-height: 824px;}
    .empty-box {
        min-height: 250px;
    }
    .products-section .empty-box {
        height: 662px;
    }
    .categories-section .empty-box {
        height: 662px;
    }
    .product-section .products .product-cart {
        min-height: 290px;
    }
    .form-wrap .form-footer {
        margin-top: 0px;
    }
}

@media (max-width: 1536px) and (max-height:864px){
    .products-section .card .card-product-body {
        max-height: 722px;
    }
    .products-section .card {min-height: 769px  ;}
    .categories-section .card .card-product-body {
        max-height: 729px;
    }
    .categories-section .card { min-height: 769px   ;}
    .empty-box {
        min-height: 200px;
    }
    .products-section .empty-box {
        height: 603px;
    }
    .categories-section .empty-box {
        height: 603px;
    }
    .product-section .products .product-cart {
        min-height: 240px;
        max-height: 250px;
    }
    .form-wrap .form-footer {
        margin-top: 0px;
    }
    .product-section .products .product-cart .cart-item .actions i {font-size: 9px;width: 20px;height: 20px;}
}
@media (max-width: 1440px) and (max-height:900px){
    .products-section .card .card-product-body {
        max-height: 707px;
    }
    .products-section .card {
        min-height: 754px;
    }
    .categories-section .card .card-product-body {
        max-height: 713px;
    }
    .categories-section .card { 
        min-height: 754px;
    }
    .empty-box {
        min-height: 220px;
    }
    .products-section .empty-box {
        height: 617px;
    }
    .categories-section .empty-box {
        height: 617px;
    }
    .product-section .products .product-cart {
        min-height: 250px;
    }
    .form-wrap .form-footer {
        margin-top: 0px;
    }
}


@media (max-width: 1280px) and (max-height:1024px){
    .products-section .card .card-product-body {
        max-height: 781px;
    }
    .products-section .card {
        min-height: 705px;
    }
    .categories-section .card .card-product-body {
        max-height: 781px;
    }
    .categories-section .card { 
        min-height: 830px;
    }
    .empty-box {
        min-height: 260px;
    }
    .products-section .empty-box {
        height: 713px;
    }
    .categories-section .empty-box {
        height: 713px;
    }
    .product-section .products .product-cart {
        min-height: 300px;
    }
    .form-wrap .form-footer {
        margin-top: 20px;
    }
    .header .navbar-brand img {
        height: 60px;
    }
}

@media (max-width: 1280px) and (max-height:960px){
    .products-section .card .card-product-body {
        max-height: 719px;
    }
    .products-section .card {
        min-height: 705px;
    }
    .categories-section .card .card-product-body {
        max-height: 725px;
    }
    .categories-section .card { 
        min-height: 705px;
    }
    .empty-box {
        min-height: 220px;
    }
    .products-section .empty-box {
        height: 651px;
    }
    .categories-section .empty-box {
        height: 657px;
    }
    .product-section .products .product-cart {
        min-height: 254px;
    }
    .form-wrap .form-footer {
        margin-top: 10px;
    }
    .header .navbar-brand img {
        height: 60px;
    }
}

@media (max-width: 1366px) and (max-height:768px){
    .products-section .card .card-product-body {
        max-height: 702px;
    }
    .products-section .card {
        min-height: 702px;
    }
    .categories-section .card .card-product-body {
        max-height: 709px;
    }
    .categories-section .card { 
        min-height: 729px;
    }
    .empty-box {
        min-height: 210px;
    }
    .products-section .empty-box {
        height: 628px;
    }
    .categories-section .empty-box {
        height: 628px;
    }
    .product-section .products .product-cart {
        min-height: 230px;
        max-height: 240px
    }
    .form-wrap .form-footer {
        margin-top: 0px;
    }
    .header .navbar-brand img {
        height: 60px;
    }
}
@media (max-width: 1024px) and (max-height:768px){
    .products-section .card .card-product-body {
        max-height: 688px;
    }
    .products-section .card {
        min-height: 736px;
    }
    .categories-section .card .card-product-body {
        max-height: 695px;
    }
    .categories-section .card { 
        min-height: 736px;
    }
    .empty-box {
        min-height: 190px;
        margin: 10px;
    }
    .products-section .empty-box {
        height: 620px;
    }
    .categories-section .empty-box {
        height: 620px;
    }
    .product-section .products .product-cart {
        min-height: 230px;
        max-height: 240px
    }
    .form-wrap .form-footer {
        margin-top: 0px;
    }
    .header .navbar-brand img {
        height: 60px;
    }
    .product-section .contact-form {
        margin-top: 8%;
    }
}