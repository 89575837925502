.custom-form {
  textarea {
    resize: none;
    height: 150px;
    margin-bottom: 30px;
    border-radius: 0px;
  }
  input {
    margin-bottom: 30px;
    border-radius: 0px;
    padding: 0.8rem 1.75rem;
  }
  button {
    color: $white !important;
    padding: 12px 75px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-size: 20px;
    text-transform: uppercase;
    &:focus {
      box-shadow: none;
    }
    // &:hover {
    //   background-color: $grey;
    //   color: $white;
    //   border: none;
    // }
 
  }
}
.ftr-about .custom-form button {
  margin-top: 20px;
}

.form-wrap {
  padding-top: 20px;
  
  .formslayouts {
    .p-title{
        position: relative;
        // top:20px;
        // bottom: 20px;
        h1{
            font-size: 20px;
            font-family: avenirBlack;
            color: $red;
            position: absolute;
            top: 5px;
            background: white;
            z-index: 1;
            left: 20px;
        }
        hr{
            background-color: $grey;
            height: 3px;
        }
    }
    .payment-method{
     
      margin: 0px 20px;
      padding: 10px 0px;
      margin-bottom: 17px;
      margin-top: 10px;
      overflow-y: auto;
      .payment-method-item{
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-content: center;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        // height: 50px;
        width: 135px;
        border-radius: 5px;
        margin:5px;
        box-shadow: 0px 0px 7px 2px #d6d6d6;
        img{
          width: 100%;
          padding: 0.3rem;
        }
        &.active{
          border: 2px solid $red;
        }
      }
    }
    .forms_wrapper {
      padding: 0 20px;
      position: relative;
      margin-bottom: 15px;

      .css-1s2u09g-control {
        border: 0px solid transparent !important;
        box-shadow: none !important;
        &:hover {
          border: 0px solid transparent !important;
          border-color: transparent !important;
          box-shadow: none !important;
        }

        .css-14dclt2-Input {
          input {
            background-color: transparent !important;
            border: 2px solid #b7b7b7;
          }
        }
      }

      .css-1pahdxg-control {
        border: 0px solid transparent !important;
        box-shadow: none !important;
        background-color: transparent !important;
        &:hover {
          border: 0px solid transparent !important;
          border-color: transparent !important;
          box-shadow: none !important;
        }
        .css-14dclt2-Input {
          input {
            background-color: transparent !important;
            border: 2px solid #b7b7b7;
          }
        }
      }
      .preffered-text{
        color: $red;
        font-family: "avenirBlack";
      }
      input {
        background-color: $white !important;
        padding: 0.5rem 0.75rem;
        border: 2px solid $light-grey;
        transition: 0.2s;
        border-radius: 5px;
        &:focus {
          outline: none;
          box-shadow: none;
          border: 2px solid $red;
        }
      }
      input[type="radio"]{
        padding: 0;
        border-radius: 100%;
        &:focus {
          outline: none;
          box-shadow: none;
          border: 2px solid $red;
        }
      }
      input[type="radio"]:checked {
        background-color: $red !important;
        transition: 0.2s;
        border-radius: 100%;
      }
      input[type="radio"]:focus{
        border: 2px solid $light-grey;
      }
      .form-check{
        padding: 2px;
        margin-right: 0;
        padding-left: 30px;
        padding-right: 12px;
        border-radius: 11px;
        border: 2px solid $light-grey;
        margin-left: 5px;
        // padding: 5px;
        i{
          color: $red;
        }
      }

      .form__field::placeholder {
        color: transparent;
      }

      .form__field:placeholder-shown ~ label {
        font-family: avenirRoman ;
        font-size: 12px;
        cursor: text;
        top: 0.9rem;
        left: 2rem;
        margin: 0;
      }

      label,
      .form__field:focus ~ .form__labels {
        position: absolute;
        top: -10px;
        left: 30px;
        display: block;
        transition: 0.2s;
        font-size: 12px;

        background-color: $white;

        padding: 0px 5px;
      }

      .form__field:focus ~ .form__label {
        color: #009788;
      }

      .form__field:focus {
        // padding-bottom: 6px;
        // border-bottom: 2px solid #009788;
      }
    }
    p {
      font-size: 12px;
      color: $red;
    }

    p::before {
      display: inline;
      color: $red;
      content: "⚠ ";
    }
    textarea {
      resize: none;
      height: 150px;
      // margin-bottom: 30px;
      border-radius: 5px;
      border: 2px solid #b7b7b7;
      font-family: "lato";
      background-color: $white !important;
      &:focus {
        outline: none;
        box-shadow: none;
        border: 2px solid $red;
      }
    }
    .css-b62m3t-container {
      background-color: $white !important;
      padding: 0.55rem 0.75rem;
      border: 2px solid #b7b7b7;
      font-family: "lato";

      &:focus {
        box-shadow: none;
        outline: none;
        border: 2px solid $red;
      }
      .css-14el2xx-placeholder {
        font-size: 12px;
        text-transform: uppercase;
        color: #9b9b9b !important;
      }
    }
    select {
      background-color: $white !important;
      padding: 1rem 0.75rem;
      border: 2px solid #b7b7b7;
      font-family: "lato";
      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
    label {
      color: #9b9b9b !important;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      transition: background-color 5000s;
      background-color: #fff !important;
    }
  }
  
  .form_text {
    color: $black;
    font-family: "lato";
    font-size: 16px;
    text-align: center;
  }
.form-footer{
  background-color: $light-grey;
  padding: 20px;
  margin-top: 100px;
  .form-btn-wrap {
    width: 100%;
    font-size: 12px;
    // margin-top: 30px;
    border: none;
    color: $white;
    border-radius: 10px;
    padding: 1rem 0;
    &:hover {
      background-color: $grey;
      color: $white;
    }
    &:focus {
      box-shadow: none;
      outline: none;
    }
    &.btn-cancel{
      background-color: $blue;
    }
    &.btn-ok{
      background-color: $red;
    }
  }
}
}


.form-wrap .not-alert p::before {
  color: #9b9b9b !important ;
  content: "";
}



