.footer{
    // position: fixed;
    // bottom: 0;
    border-top: 1px solid $grey;
    padding: 20px 0;
    .subftr-one{
        p{
            color: $dark-grey;
            margin: 0;
            i{
                color: $red;
                margin-right: 10px;
            }
            a{
                color: inherit;
                text-decoration: none;
            }

        }
    }
    .subftr-sec{
        p{
            color: $dark-grey;
            font-size: 12px;
            margin: 0;
            span{
                color: $red;
                font-weight: 700;
            }
        }
    }
}
